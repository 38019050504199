import React from "react"
import { Link } from "gatsby"

export default function Footer() {

  function handleSubmit(event) {
    event.preventDefault();
    document.getElementById("subscription").reset();
    document.getElementById("messageForSub").classList.add('d-block');
  }

  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-info">
                <h3>DataAlp</h3>
                <p className="pb-3">
                  <em>A product of AGOS (Alp Group of Online Services) LLC</em>
                </p>
                <p>
                  1309 Coffeen Avenue STE 1200
                  <br />
                  Sheridan, WY 82801, USA
                  <br />
                  <strong>Phone:</strong> +1 (307) 429-3885
                  <br />
                  <strong>Email:</strong> alp@dataalp.com
                  <br />
                </p>
                <div className="social-links mt-3">
                  {/* <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
                                                <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a> */}
                  <Link
                    to="https://www.instagram.com/data_alp/?igshid=f0ma7oea28xr"
                    target="_blank"
                    className="instagram"
                  >
                    <i className="bx bxl-instagram"></i>
                  </Link>
                  {/* <a href="https://www.instagram.com/data_alp/?igshid=f0ma7oea28xr" target={blank} className="instagram"><i className="bx bxl-instagram"></i></a> */}
                  {/* <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a> */}
                  <Link
                    to="https://pk.linkedin.com/company/dataalp?trk=public_profile_topcard-current-company"
                    target="_blank"
                    className="linkedin"
                  >
                    <i className="bx bxl-linkedin"></i>
                  </Link>
                  {/* <a href="https://pk.linkedin.com/company/dataalp?trk=public_profile_topcard-current-company" className="linkedin"><i className="bx bxl-linkedin"></i></a> */}
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i> <a href="/">Home</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="/about-us">About us</a>
                </li>
                {/* <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="/get-report">Services</a>
                </li> */}
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="/terms-of-use">Terms of use</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="/privacy-policy">Privacy policy</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="/new-supervised">Supervised ML</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="/new-unsupervised">Unsupervised ML</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-4 col-md-6 footer-newsletter">
              <h4>Our Newsletter</h4>
              <p>Enter your email address and subscribe to our newsletter</p>
              <form id="subscription" onSubmit={handleSubmit}>
                <input type="email" name="email"></input>
                <input type="submit" value="Subscribe"></input>
              </form>
              <div id="messageForSub"
                className="alert alert-success mt-3 hide"
                role="alert" 
              >
                Thank You! We have added your email in our list.
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="copyright">
            &copy; Copyright 2022{" "}
            <strong>
              <span>DataAlp</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </div>
      <a style={{display: 'block', textAlign: 'center', color: '#f5deb3', fontSize: '0.6rem'}} href="https://storyset.com/business">Illustrations by Storyset</a>
    </footer>
  )
}
