import React, { useEffect, useState } from "react";
import scrollTo from 'gatsby-plugin-smoothscroll';
import SignUpModal from "./modal";


export default function Navbar() {

    const [scroll, setScroll] = useState(false);
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const [formType, setFormType] = useState("signUp");
  
    function handleShow(breakpoint) {
      setFullscreen(breakpoint);
      setShow(true);
    }

    function switchForm(formType) {
      setFormType(formType);
    }

    useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY < 90);
      });
    }, []);


      /**
   * Easy selector helper function
   */
  const select = (el, all = false) => {
    el = el.trim()
    if (all) {
      return [...document.querySelectorAll(el)]
    } else {
      return document.querySelector(el)
    }
  }

  /**
   * Easy event listener function
   */
  const on = (type, el, listener, all = false) => {
    let selectEl = select(el, all)
    if (selectEl) {
      if (all) {
        selectEl.forEach(e => e.addEventListener(type, listener))
      } else {
        selectEl.addEventListener(type, listener)
      }
    }
  }

    function toggleMobileNav() {
        select('#navbar').classList.toggle('navbar-mobile')
        select('#navbar').classList.toggle('bi-list')
        select('#navbar').classList.toggle('bi-x')
    }

    // on('click', '.navbar .dropdown > a', function (e) {
    //     if (select('#navbar').classList.contains('navbar-mobile')) {
    //       e.preventDefault()
    //       this.nextElementSibling.classList.toggle('dropdown-active')
    //     }
    //   }, true)

    function mobileNavDropdownsActivate(e) {
        debugger;
        if (select('#navbar').classList.contains('navbar-mobile')) {
            
            select('#testu').classList.toggle('dropdown-active')
          }
    }



    return (
        <header id="header" className={scroll ? "fixed-top d-flex align-items-center header-transparent" : "fixed-top d-flex align-items-center header-scrolled"  }>
            <div className="container d-flex align-items-center justify-content-between">

                <div className="logo">

                    {/* <h1><img src={Logo} className="me-3" alt=""></img><a href="index.html"><span>DataAlp</span></a></h1> */}
                    <h1><a href="/"><span>DataAlp</span></a></h1>

                </div>

                <nav id="navbar" className="navbar" >
                    <ul>
                        <li><a className="nav-link scrollto active" href="/">Home</a></li>
                        <li className="dropdown" ><a href="/new-supervised"><span>Products</span> <i className="bi bi-chevron-down"></i></a>
                            <ul>
                                <li>
                                    <a className="nav-link scrollto" href="/new-supervised">Supervised ML</a>
                                    <span style={{fontSize: '0.8rem',color: 'black', textAlign: 'center', opacity: '0.6', padding: '0px 0 10px 20px'}}>Train AI for Predictions</span>
                                </li>
                                <li>
                                    <a href="/new-unsupervised">Unsupervised ML</a>
                                    <span style={{fontSize: '0.8rem',color: 'black', textAlign: 'center', opacity: '0.6', padding: '0px 0 10px 20px'}}>Train AI for Associations and Patterns</span>
                                </li>

                            </ul>
                        </li>
                        <li><a className="nav-link scrollto" href="/about-us">About Us</a></li>
                        <li><a className="nav-link scrollto" href="/support-us">Support Us</a></li>
                        <li><a className="nav-link scrollto" href="/hire-us">Hire An Alp</a></li>
                        <li><a className="nav-link scrollto" href="/#contact">Contact Us</a></li>
                        {/* <li><button className="btn btn-success" style={{backgroundColor: "#1acc8d", marginLeft: "30px", width: "120px", borderColor: 'none', border: '0px', borderRadius: '16px'}} type="submit" onClick={() => {handleShow(true)}}>Sign Up</button></li> */}
                        
                    </ul>
                    <i className="bi bi-list mobile-nav-toggle" onClick={toggleMobileNav}></i>
                </nav>
              <SignUpModal show={show} fullscreen={fullscreen} setShow={setShow} formType={formType} setFormType={setFormType}  />
            </div>
        </header>
    )
}