import React, { useState } from "react"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

export default function SignIn(props) {
  const [email, setEmail] = useState("") // '' is the initial state value
  const [password, setPassword] = useState("") // '' is the initial state value
  const [isError, setIsError] = useState(false) // '' is the initial state value

  function login() {
    var formData = new FormData()
    formData.append("email", email)
    formData.append("password", password)

    fetch(`http://localhost:5000/supervised`, {
      method: "POST",
      body: formData,
    }).then(res => {

    })
  }

  return (
    <div>
          <div className="row justify-content-center" style={{color: 'white'}}>
            <div className="col-md-8">
              <div className="mb-4">
              <h3>Sign In</h3>
            </div>
            <form>
              <div className="form-group first">
                <label for="username first">Email</label>
                <input type="text" className="form-control" id="email"></input>

              </div>
              <div className="form-group last">
                <label for="password ">Password</label>
                <input type="password" className="form-control" id="password"></input>
                
              </div>

              <div className="d-grid gap-2 mt-5">
              <button type="submit" className="btn btn-block btn-success" style={{backgroundColor: '#1ACC8D'}}>Sign In</button>
              </div>

              
              <div><p className="mt-5" style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={()=>{props.setFormType("signUp")}}>Create An Account</p></div>

            </form>
            </div>
          </div>
    </div>
  )
}
