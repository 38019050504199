import React, { useState } from "react"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"

export default function SignUp(props) {
  const [email, setEmail] = useState("") // '' is the initial state value
  const [username, setUsername] = useState("") // '' is the initial state value
  const [password, setPassword] = useState("") // '' is the initial state value
  const [confirmPassword, setConfirmPassword] = useState("") // '' is the initial state value
  const [isError, setIsError] = useState(false) // '' is the initial state value
  const [errorMsg, setErrorMsg] = useState("") // '' is the initial state value

  function signUp() {
    var formData = new FormData()
    formData.append("email", email)
    formData.append("username", username)
    formData.append("password", password)

    fetch(`http://localhost:5000/supervised`, {
      method: "POST",
      body: formData,
    }).then(res => {

    })
  }

  return (
    <div>
          <div className="row justify-content-center" style={{color: 'white'}}>
            <div className="col-md-8">
              <div className="mb-4">
              <h3>Sign Up</h3>
            </div>
            <form>
              <div className="form-group first">
                <label for="username">Email</label>
                <input type="text" className="form-control" id="email"></input>

              </div>
              <div className="form-group">
                <label for="username">username</label>
                <input type="text" className="form-control" id="username"></input>

              </div>
              <div className="form-group">
                <label for="password">Password</label>
                <input type="password" className="form-control" id="password"></input>
                
              </div>
              <div className="form-group last mb-4">
                <label for="password">Confirm Password</label>
                <input type="password" className="form-control" id="confirmPassword"></input>
                
              </div>

              <div className="d-grid gap-2">
              <button type="submit" className="btn btn-block btn-success" style={{backgroundColor: '#1ACC8D'}}>Sign Up</button>
              </div>

              
              <div><p className="mt-5" style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={()=>{props.setFormType("signIn")}}>Already have an account? Sign In</p></div>

            </form>
            </div>
          </div>
    </div>
  )
}
