import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"
import Card from "react-bootstrap/Card"
import SignIn from "./signIn"
import SignUp from "./signup"
import { StaticImage } from "gatsby-plugin-image"

export default function SignUpModal(props) {
  const [formType, setFormType] = useState(props.formType)

  return (
    <Modal
      show={props.show}
      fullscreen={props.fullscreen}
      onHide={() => props.setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: "center" }}></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                      <StaticImage
                      src="../images/undraw_remotely_2j6y.svg"
                      alt=""
                      className="img-fluid"
                    />
              </div>
              <div class="col-md-6 contents">
                {formType === "signUp" && <SignUp setFormType={setFormType} />}
                {formType === "signIn" && <SignIn setFormType={setFormType} />}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
